<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Layout from '@layouts/main.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import PosService from '@src/services/PosService'
import posModal from '@src/router/views/pos/posModal.vue'
import posFilterModal from '@src/router/views/pos/posFilterModal.vue'
import GridFormModal from '@src/components/GridFormModal.vue'
import FilterBar from '@src/components/FilterBar'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import statesWithCities from '@src/assets/resources/statesWithCities'
import pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import importModal from '@views/importJob/importJobModal'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import DeleteAllButton from '@/src/components/DeleteAllButton.vue'
import DeleteSelectedButton from '@/src/components/DeleteSelectedButton.vue'
import posFields from '@/src/fields/posFields.vue'
import businessUnitChildrenFields from '@/src/fields/childrens/businessUnitChildrenFields.vue'
import { debounce } from 'lodash'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Layout,
        Breadcrumb,
        PageHeader,
        posModal,
        posFilterModal,
        GridFormModal,
        FilterBar,
        ConfirmationFormModal,
        FeedbackModal,
        importModal,
        AddLabelButton,
        ImportButton,
        ExportButton,
        CountTableButton,
        MoreTableButton,
        FilterLabelButton,
        DeleteAllButton,
        DeleteSelectedButton,
    },
    mixins: [
        pagination,
        swalFeedback,
        filterVuetable,
        posFields,
        businessUnitChildrenFields
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            additionalParameters: {
                with: [
                    'chain',
                    'channel',
                    'category',
                    'city',
                    'state',
                    'regional',
                    'flag',
                    'businessUnits'
                ],
            },
            modal: {
                id: null,
                address: null,
                chain: null,
                channel: null,
                flag: null,
                regional: null,
                category: null,
                city: {
                    type: Object,
                    default: () => {
                        return {
                            id: null,
                            name: '',
                        }
                    },
                },
                complement: null,
                district: null,
                externalCode: null,
                chainExternalCode: null,
                hasImage: false,
                humanReadableAddress: null,
                latitude: null,
                longName: null,
                longitude: null,
                name: null,
                number: null,
                placeId: null,
                postalCode: null,
                registeredNumber: null,
                shortName: null,
                state: {
                    type: Object,
                    default: () => {
                        return {
                            id: null,
                            letter: '',
                            name: '',
                            slug: '',
                        }
                    },
                },
                number_checkout: 0,
                business_units: [],
                commecial_responsible_name: null,
                commecial_responsible_email: null,
                merchandising_responsib_name: null,
                merchandising_responsib_email: null,
            },
            deleteType: 'delete_selected',
            deleteSelectedLoading: false,
            deleteAllLoading: false,
            modalIsEdit: false,
            modalResponseErrors: {},
            data: [],
            perPage: 10,
            validModel: false,
            operationType: '',
            css: {
                pagination: {
                    activeClass: 'btn-primary text-white',
                    pageClass: 'btn btn-rounded',
                    linkClass: 'btn',
                    icons: {
                        prev: '',
                        next: '',
                    },
                },
            },
            filterText: '',
            statesWithCities: statesWithCities,
            filters: {
                business_units: [],
                channels: [],
                chains: [],
                regionals: [],
                flags: [],
                categories: [],
                statesAndCities: [],
            },
        }
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.pos',
                modifier: 2,
            }),
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nNewPos() {
            return this.getI18n(i18nKey, 'BUTTONS.new_pos')
        },
        i18nPos() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.pos',
                modifier: 2,
            })
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                with: [
                    'chain',
                    'channel',
                    'category',
                    'regional',
                    'flag',
                    'city',
                    'state',
                    'businessUnits'
                ],
                search: '',
            }
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText};external_code:${searchText};address:${searchText};channel.name:${searchText};`
                this.additionalParameters.search += `category.name:${searchText};chain.name:${searchText};regional.name:${searchText};flag.name:${searchText};`
                this.additionalParameters.search += `commecial_responsible_name:${searchText};commecial_responsible_email:${searchText};`
                this.additionalParameters.search += `merchandising_responsib_name:${searchText};merchandising_responsib_email:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        createAdditionalParameters() {
            const search = this.createSearch({
                name: this.filters.name,
                address: this.filters.address,
            })

            return this.formatParameters({
                with: [
                    'chain',
                    'channel',
                    'category',
                    'regional',
                    'flag',
                    'city',
                    'state',
                    'businessUnits'
                ],
                search,
                city_id: this.filters.statesAndCities.filter((id) => typeof id === 'number'),
                states: this.filters.statesAndCities.filter((id) => typeof id === 'string'),
                chain_id: this.filters.chains.map((chain) => chain.id),
                category_id: this.filters.categories.map((category) => category.id),
                regional_id: this.filters.regionals.map((regional) => regional.id),
                flag_id: this.filters.flags.map((flags) => flags.id),
                channel_id: this.filters.channels.map((channel) => channel.id),
                business_unit_id: this.filters.business_units.map((businessUnit) => businessUnit.id),
                searchJoin: search.search(';') > 0 ? 'and' : '',
            })
        },
        cleanFilter() {
            Object.assign(this.filters, {
                business_units: [],
                channels: [],
                chains: [],
                regionals: [],
                flags: [],
                categories: [],
                statesAndCities: [],
            })

            this.additionalParameters = {
                with: [
                    'chain',
                    'channel',
                    'category',
                    'regional',
                    'flag',
                    'city',
                    'state',
                    'businessUnits'
                ],
            }

            this.refreshTable()
        },
        fetch(url, params) {
            return PosService.fetchVuetable(url, params)
        },
        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },
        cleanModal() {
            this.$nextTick(() => this.$refs.posModal.cleanModal())
        },
        showModal() {
            this.$nextTick(() => this.$refs.posModal.showModal())
        },
        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },
        openModalToEdit(pos) {
            this.modalIsEdit = false
            this.replaceModalData(pos)
            this.showModal()
        },
        replaceModalData(data) {
            Object.assign(this.modal, {
                ...data,
                externalCode: data.external_code,
                chainExternalCode: data.chain_external_code,
                registeredNumber: data.cnpj,
                business_units: data.business_units,
                number_checkout: data.number_checkout ?? 0,
            })
        },
        filter(appliedFilters) {
            this.filters = appliedFilters
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },
        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.posFilterModal.showModal(this.filters)
            )
        },
        openModalBusinessUnits(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridBusinessUnitsModal.showModal())
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
        handleDelete(type) {
            if (type === 'delete_selected' && this.deleteSelectedLoading) {
                return
            } else if (type === 'delete_all' && this.deleteAllLoading) {
                return
            }

            this.deleteType = type
            if (type === 'delete_selected') {
                this.$refs.confirmationFormModal.showModal(false, this.deletedSelected)
            }
            else if (type === 'delete_all') {
                this.$refs.confirmationFormModal.showModal(false, this.deletedAllFiltered)
            }
        },
        async deletedSelected(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.deleteSelectedLoading = true
            await this.deletePos(
                PosService.deleteMultiple({
                    point_of_sale_id: this.$refs.vuetable.$refs.vuetable.selectedTo,
                })
            )
        },
        async deletedAllFiltered(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.deleteAllLoading = true
            await this.deletePos(
                PosService.deleteMultiple(
                    this.additionalParameters
                )
            )
        },
        async deletePos(deleteFunction) {
            const response = await deleteFunction
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$refs.feedbackModal.showModal(statusCode, response.data)
                this.refreshTable()
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }
            this.deleteSelectedLoading = false
            this.deleteAllLoading = false
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                    <filter-label-button @onClick="showFilterModal" />
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'POS')"
                            :title="i18nNewPos"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="point_of_sale"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <b-row>
                    <div class="col-sm-6">
                        <PageHeader :title="i18nPos" />
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <delete-selected-button
                                v-if="$can('DELETE', 'POS')"
                                :busy="deleteSelectedLoading"
                                :disabled="deleteAllLoading"
                                @onClick="handleDelete('delete_selected')"
                            />
                            <delete-all-button
                                v-if="$can('DELETE', 'POS')"
                                :busy="deleteAllLoading"
                                :disabled="deleteSelectedLoading"
                                @onClick="handleDelete('delete_all')"
                            />
                        </div>
                    </div>
                </b-row>
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="posFields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="business_units" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.business_units.length"
                                @onClick="openModalBusinessUnits(props.rowData)"
                            />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <confirmation-form-modal
            ref="confirmationFormModal"
            :title="i18nPos"
            :operation="deleteType"
        />
        <feedback-modal
            ref="feedbackModal"
            :title="i18nPos"
            :operation="deleteType"
        />
        <pos-modal
            ref="posModal"
            :modal="modal"
            :modal-is-edit="modalIsEdit"
            @refresh="reloadTable"
        />
        <pos-filter-modal
            ref="posFilterModal"
            :handle-submit="filter"
        />
        <grid-form-modal
            ref="gridBusinessUnitsModal"
            :modal="modal"
            :items="modal.business_units"
            :fields="businessUnitChildrenFields"
            :title="
                getI18nModified({
                    prefix: 'BUSINESS_UNITS',
                    suffix: 'TITLES.business_unit',
                    modifier: 2,
                })
            "
        />
        <import-modal
            ref="importModal"
            :csv-type="'point_of_sale'"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TITLES.point_of_sale',
                    modifier: 2,
                })
            "
        />
    </Layout>
</template>
