
<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <pos-category-modal
                    ref="posCategoryModal"
                    @refresh="reloadCategoryOptions"
                />
                <pos-chain-modal
                    ref="posChainModal"
                    @refresh="reloadChainOptions"
                />
                <pos-channel-modal
                    ref="posChannelModal"
                    @refresh="reloadChannelOptions"
                />
                <pos-regional-modal
                    ref="posRegionalModal"
                    @refresh="reloadRegionalOptions"
                />
                <pos-flag-modal
                    ref="posFlagModal"
                    @refresh="reloadFlagOptions"
                />
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nPos"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nPos"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'POS')"
                                :title="getI18n(i18nKey, 'TITLES.pos')"
                                :busy="editLoading"
                                :disabled="deleteLoading"
                                @onClick="handleEdit"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'POS')"
                                :title="getI18n(i18nKey, 'TITLES.pos')"
                                :busy="deleteLoading"
                                :disabled="editLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="businessunit-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated">
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nName"
                                    label-for="name-input"
                                    class="required label-pdv"
                                    :invalid-feedback="i18nChainInvalidNameLabel"
                                >
                                    <b-form-input
                                        id="name-input"
                                        v-model.trim="$v.posModal.name.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        type="text"
                                        :state="validateField('name', 'posModal')"
                                        :disabled="!isEdit || submitLoading"
                                        autofocus
                                        autocomplete="off"
                                        @input="clearResponseError('name', 'posModal')"
                                        @blur="$v.posModal.name.$touch"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    v-mask="'##.###.###/####-##'"
                                    :label="i18nRegisteredNumber"
                                    label-for="registered-input"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_registered_number')"
                                >
                                    <b-form-input
                                        id="registeredNumber-input"
                                        v-model="$v.posModal.registeredNumber.$model"
                                        class="input-pdv-blue"
                                        name="registeredNumber"
                                        :state="validateField('registeredNumber', 'posModal')"
                                        :disabled="!isEdit || submitLoading"
                                        autocomplete="off"
                                        maxlength="18"
                                        @input="clearResponseError('registeredNumber', 'posModal')"
                                        @blur="$v.posModal.registeredNumber.$touch;"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <b-form-group
                                    :label="i18nExternalCode"
                                    label-for="externalCode-input"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_external_code')"
                                >
                                    <b-form-input
                                        id="externalCode-input"
                                        v-model.trim="$v.posModal.externalCode.$model"
                                        class="input-pdv-blue"
                                        name="externalCode"
                                        :state="validateField('externalCode', 'posModal')"
                                        :disabled="!isEdit || submitLoading"
                                        autocomplete="off"
                                        @blur="$v.posModal.externalCode.$touch"
                                        @input="clearResponseError('externalCode', 'posModal')"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <b-form-group
                                    :label="i18nChainExternalCode"
                                    label-for="chainExternalCode-input"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_chain_external_code')"
                                >
                                    <b-form-input
                                        id="chainExternalCode-input"
                                        v-model.trim="$v.posModal.chainExternalCode.$model"
                                        class="input-pdv-blue"
                                        name="chainExternalCode"
                                        :state="validateField('chainExternalCode', 'posModal')"
                                        :disabled="!isEdit || submitLoading"
                                        autocomplete="off"
                                        @blur="$v.posModal.chainExternalCode.$touch"
                                        @input="clearResponseError('chainExternalCode', 'posModal')"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <label class="label-pdv">
                                    {{ i18nChain }}
                                </label>
                                <a
                                    v-if="$can('CREATE', 'POSCHAIN') && isEdit"
                                    href="javascript: void(0);"
                                    class="newChainModal"
                                    :title="i18nNewChain"
                                    @click="showChainModal"
                                >
                                    <i class="fe-plus-circle icon-green ml-1" />
                                </a>
                                <multiSelectWithService
                                    :id="'modal-chain'"
                                    ref="chainMultiselect"
                                    v-model="posModal.chain"
                                    :input-function="$v.posModal.chain.id.$touch"
                                    :select-class="validationClass($v.posModal.chain.id)"
                                    :service="'pos-chains'"
                                    :searchable="true"
                                    :multiple="false"
                                    :disabled="!isEdit || submitLoading"
                                    @input="changeChainId"
                                />
                                <label v-if="$v.posModal.chain.id.$dirty && $v.posModal.chain.id.$invalid" class="label-pdv-invalid">
                                    {{ getI18n('ERROR_CODES.invalid_chain') }}
                                </label>
                            </b-col>
                            <b-col md="4">
                                <label class="label-pdv">
                                    {{ i18nFlag }}
                                </label>
                                <a
                                    v-if="$can('CREATE', 'POSFLAG') && isEdit"
                                    href="javascript: void(0);"
                                    class="newFlagModal"
                                    :title="i18nNewFlag"
                                    @click="showFlagModal"
                                >
                                    <i class="fe-plus-circle icon-green ml-1" />
                                </a>
                                <multiSelectWithService
                                    :id="'modal-flag'"
                                    ref="flagMultiselect"
                                    v-model="posModal.flag"
                                    :input-function="$v.posModal.flag.id.$touch"
                                    :select-class="validationClass($v.posModal.flag.id)"
                                    :service="'pos-flags'"
                                    :parameters="flagsParameters"
                                    :searchable="true"
                                    :multiple="false"
                                    :disabled="posModal.chain === null || !isEdit || submitLoading"
                                />
                                <label v-if="$v.posModal.flag.id.$dirty && $v.posModal.flag.id.$invalid" class="label-pdv-invalid">
                                    {{ getI18n('ERROR_CODES.invalid_flag') }}
                                </label>
                            </b-col>
                            <b-col md="4">
                                <label class="label-pdv">
                                    {{ i18nChannel }}
                                </label>
                                <a
                                    v-if="$can('CREATE', 'POSCHANNEL') && isEdit"
                                    href="javascript: void(0);"
                                    class="newChannelModal"
                                    :title="i18nNewChannel"
                                    @click="showChannelModal"
                                >
                                    <i class="fe-plus-circle icon-green ml-1" />
                                </a>
                                <multiSelectWithService
                                    :id="'modal-channel'"
                                    ref="channelMultiselect"
                                    v-model="posModal.channel"
                                    :input-function="$v.posModal.channel.id.$touch"
                                    :select-class="validationClass($v.posModal.channel.id)"
                                    :service="'pos-channels'"
                                    :searchable="true"
                                    :multiple="false"
                                    :disabled="!isEdit || submitLoading"
                                />
                                <label v-if="$v.posModal.channel.id.$dirty && $v.posModal.channel.id.$invalid" class="label-pdv-invalid">
                                    {{ getI18n('ERROR_CODES.invalid_channel') }}
                                </label>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col md="4">
                                <label class="label-pdv">
                                    {{ i18nRegional }} *
                                </label>
                                <a
                                    v-if="$can('CREATE', 'POSREGIONAL') && isEdit"
                                    href="javascript: void(0);"
                                    class="newRegionalModal"
                                    :title="i18nNewRegional"
                                    @click="showRegionalModal"
                                >
                                    <i class="fe-plus-circle icon-green ml-1" />
                                </a>
                                <multiSelectWithService
                                    :id="'modal-regional'"
                                    ref="regionalMultiselect"
                                    v-model="posModal.regional"
                                    :input-function="$v.posModal.regional.id.$touch"
                                    :select-class="validationClass($v.posModal.regional.id)"
                                    :service="'pos-regionals'"
                                    :searchable="true"
                                    :multiple="false"
                                    :disabled="!isEdit || submitLoading"
                                    @input="changeRegionalId"
                                />
                                <label v-if="$v.posModal.regional.id.$dirty && $v.posModal.regional.id.$invalid" class="label-pdv-invalid">
                                    {{ getI18n('ERROR_CODES.invalid_regional') }}
                                </label>
                            </b-col>
                            <b-col md="4">
                                <label class="label-pdv">
                                    {{ i18nCategory }}
                                </label>
                                <a
                                    v-if="$can('CREATE', 'POSCATEGORY') && isEdit"
                                    href="javascript: void(0);"
                                    class="newCategoryModal"
                                    :title="i18nNewCategory"
                                    @click="showCategoryModal"
                                >
                                    <i class="fe-plus-circle icon-green ml-1" />
                                </a>
                                <multiSelectWithService
                                    :id="'modal-category'"
                                    ref="categoryMultiselect"
                                    v-model="posModal.category"
                                    :input-function="$v.posModal.category.id.$touch"
                                    :select-class="validationClass($v.posModal.category.id)"
                                    :service="'pos-categories'"
                                    :searchable="true"
                                    :multiple="false"
                                    :disabled="!isEdit || submitLoading"
                                />
                                <label v-if="$v.posModal.category.id.$dirty && $v.posModal.category.id.$invalid" class="label-pdv-invalid">
                                    {{ getI18n('ERROR_CODES.invalid_category') }}
                                </label>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nNumberCheckout"
                                    label-for="modal-number-checkout"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_number')"
                                >
                                    <b-form-input
                                        id="modal-number-checkout"
                                        v-model.number="$v.posModal.number_checkout.$model"
                                        name="number_checkout"
                                        aria-describedby="input-1-live-feedback"
                                        type="number"
                                        class="input-pdv-blue"
                                        :state="validateField('number_checkout', 'posModal')"
                                        :disabled="!isEdit || submitLoading"
                                        @input="clearResponseError('number_checkout', 'posModal')"
                                        @blur="$v.posModal.number_checkout.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nAddress">
                        <b-row>
                            <b-col md="12">
                                <google-auto-complete
                                    id="autocomplete_address"
                                    ref="gautocomplete"
                                    :modal="gModal"
                                    :required="true"
                                    :disabled="!isEdit || submitLoading"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nResponsible">
                        <topic-header
                            :caption="i18nCommecialResponsible"
                            icon="fe-shopping-cart"
                        />
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nName"
                                    label-for="modal-commecial-responsible-name"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                                >
                                    <b-form-input
                                        id="modal-commecial-responsible-name"
                                        v-model.trim="$v.posModal.commecial_responsible_name.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :disabled="!isEdit || submitLoading"
                                        :state="validateField('commecial_responsible_name', 'posModal')"
                                        autocomplete="off"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nEmail"
                                    label-for="modal-email"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_email')"
                                >
                                    <b-form-input
                                        id="modal-email"
                                        v-model.trim="$v.posModal.commecial_responsible_email.$model"
                                        class="input-pdv-blue"
                                        name="email"
                                        aria-describedby="input-1-live-feedback"
                                        type="email"
                                        autocomplete="off"
                                        :disabled="!isEdit || submitLoading"
                                        :state="validateField('commecial_responsible_email', 'posModal')"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <topic-header
                            :caption="i18nMerchandisingResponsible"
                            icon="fe-layout"
                        />
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nName"
                                    label-for="modal-merchandising-responsible-name"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                                >
                                    <b-form-input
                                        id="modal-merchandising-responsible-name"
                                        v-model.trim="$v.posModal.merchandising_responsib_name.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :disabled="!isEdit || submitLoading"
                                        :state="validateField('merchandising_responsib_name', 'posModal')"
                                        autocomplete="off"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nEmail"
                                    label-for="modal-email"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_email')"
                                >
                                    <b-form-input
                                        id="modal-email"
                                        v-model.trim="$v.posModal.merchandising_responsib_email.$model"
                                        class="input-pdv-blue"
                                        name="email"
                                        aria-describedby="input-1-live-feedback"
                                        type="email"
                                        autocomplete="off"
                                        :disabled="!isEdit || submitLoading"
                                        :state="validateField('merchandising_responsib_email', 'posModal')"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nBusinessUnit">
                        <b-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nBusinessUnit"
                                    label-for="add-business-unit-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-business-unit-input'"
                                        ref="businessUnitMultiselect"
                                        v-model="businessUnitName"
                                        :service="'business-units'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="posModal.business_units"
                                    :fields="fields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveBusinessUnit"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="tabIndex !== 3"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex++"
                />
                <save-button
                    v-else-if="isPosCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import { required, minLength, integer, email } from 'vuelidate/lib/validators'
import PosService from '@src/services/PosService'
import GoogleAutoComplete from '@components/googleAutoComplete.vue'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import posCategoryModal from '@views/posCategory/posCategoryModal.vue'
import posChainModal from '@views/posChain/posChainModal.vue'
import posChannelModal from '@views/posChannel/posChannelModal.vue'
import posRegionalModal from '@views/posRegional/posRegionalModal.vue'
import posFlagModal from '@views/posFlag/posFlagModal.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import TopicHeader from '@src/components/TopicHeader.vue'
import { i18n } from '@src/i18n'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        GoogleAutoComplete,
        multiSelectWithService,
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        posCategoryModal,
        posChainModal,
        posChannelModal,
        posRegionalModal,
        posFlagModal,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
        DataModeVuetable,
        TopicHeader,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        filterVuetable,
        fieldsValidation
    ],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            gModal: {},
            posModal: this.modal,
            tabIndex: 0,
            isEdit: false,
            editLoading: false,
            submitLoading: false,
            deleteLoading: false,
            money: {
                decimal: i18n.t('CURRENCY.decimal'),
                thousands: i18n.t('CURRENCY.thousands'),
                prefix: i18n.t('CURRENCY.prefix'),
                suffix: i18n.t('CURRENCY.suffix'),
                precision: 2,
                masked: false,
            },
            flagsParameters: {},
            fields: [
                {
                    key: 'name',
                    label: this.getI18n('BUSINESS_UNITS', 'TITLES.business_unit'),
                    sortable: true,
                },
                {
                    key: 'contract_value',
                    label: this.getI18n('BUSINESS_UNITS', 'TITLES.contract_value'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'contract_time',
                    label: this.getI18n('BUSINESS_UNITS', 'TITLES.contract_time'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            businessUnitName: '',
        }
    },
    validations: {
        posModal: {
            name: {
                required,
                minLength: minLength(2),
            },
            externalCode: {
                required,
                minLength: minLength(2),
            },
            chainExternalCode: {
                minLength: minLength(2),
            },
            registeredNumber: {
                registeredNumber(value) {
                    if (value) {
                        return this.validateRegisteredNumber(value)
                    }
                    return true
                },
            },
            number_checkout: {
                integer,
            },
            regional: {
                id: {
                    required,
                },
            },
            chain: {
                id: {},
            },
            channel: {
                id: {},
            },
            category: {
                id: {},
            },
            flag: {
                id: {},
            },
            commecial_responsible_name: {
                minLength: minLength(3),
            },
            commecial_responsible_email: {
                email,
            },
            merchandising_responsib_name: {
                minLength: minLength(3),
            },
            merchandising_responsib_email: {
                email,
            },
        },
    },
    computed: {
        isPosCreated() {
            return this.posModal.id > 0
        },
        i18nTitleModal() {
            if (this.isPosCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nPos}`
                }
                return this.posModal.name
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_pos')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nEmail() {
            return this.getI18n(i18nCommon, 'email')
        },
        i18nFlag() {
            return this.getI18n(i18nKey, 'TITLES.flag')
        },
        i18nNewFlag() {
            return this.getI18n(i18nKey, 'BUTTONS.new_flag')
        },
        i18nRegional() {
            return this.getI18n(i18nKey, 'TITLES.regional')
        },
        i18nNewRegional() {
            return this.getI18n(i18nKey, 'BUTTONS.new_regional')
        },
        i18nChannel() {
            return this.getI18n(i18nKey, 'TITLES.channel')
        },
        i18nNewChannel() {
            return this.getI18n(i18nKey, 'BUTTONS.new_channel')
        },
        i18nChain() {
            return this.getI18n(i18nKey, 'TITLES.chain')
        },
        i18nNewChain() {
            return this.getI18n(i18nKey, 'BUTTONS.new_chain')
        },
        i18nCategory() {
            return this.getI18n(i18nKey, 'TITLES.category')
        },
        i18nNewCategory() {
            return this.getI18n(i18nKey, 'BUTTONS.new_category')
        },
        i18nChainInvalidNameLabel() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        i18nRegisteredNumber() {
            return this.getI18n(i18nKey, 'FIELDS.registered_number')
        },
        i18nExternalCode() {
            return this.getI18n(i18nKey, 'FIELDS.external_code')
        },
        i18nChainExternalCode() {
            return this.getI18n(i18nKey, 'FIELDS.chain_external_code')
        },
        i18nNumberCheckout() {
            return this.getI18n(i18nKey, 'FIELDS.number_checkout')
        },
        modalOperation() {
            return this.isPosCreated ? 'edited' : 'created'
        },
        i18nCommecialResponsible() {
            return this.getI18n(i18nKey, 'FIELDS.commecial_responsible')
        },
        i18nMerchandisingResponsible() {
            return this.getI18n(i18nKey, 'FIELDS.merchandising_responsible')
        },
        i18nPos() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.pos',
                modifier: 1,
            })
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nResponsible() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'FIELDS.responsible',
                modifier: 2,
            })
        },
        i18nAddress() {
            return this.getI18n(i18nCommon, 'address')
        },
        i18nBusinessUnit() {
            return this.getI18nModified({
                prefix: 'COMMON.business_unit',
                modifier: 2,
            })
        },
    },
    watch: {
        businessUnitName(value) {
            if (value) {
                const index = this.posModal.business_units.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.posModal.business_units.push(value)
                }
                this.businessUnitName = ''
                this.$refs.businessUnitMultiselect.$el.focus()
            }
        }
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (this.isEdit) {
                if (oldTabIndex === 0) {
                    this.$v.posModal.$touch()
                    if (this.$v.posModal.$invalid) {
                        event.preventDefault();
                    }
                }
                else if (oldTabIndex === 1 && newTabIndex > oldTabIndex) {
                    this.$refs.gautocomplete.$v.gModal.$touch()
                    if (this.$refs.gautocomplete.$v.gModal.$invalid) {
                        event.preventDefault();
                    }
                }
                else if (oldTabIndex === 2 && newTabIndex > oldTabIndex) {
                    this.$v.posModal.$touch()
                    if (this.$v.posModal.$invalid) {
                        event.preventDefault();
                    }
                }

                if (newTabIndex > oldTabIndex && (newTabIndex - oldTabIndex > 1)) {
                    event.preventDefault();
                    this.tabIndex = oldTabIndex + 1
                }
            }
        },
        changeRegionalId(e) {
            this.posModal.regional_id = e ? e.id : null
            this.clearResponseError('regional', 'posModal')
        },
        showModal() {
            this.businessUnitName = ''
            this.tabIndex = 0
            this.isEdit = this.modalIsEdit
            this.editLoading = false
            this.submitLoading = false
            this.deleteLoading = false
            this.replaceModalData(this.posModal)
            this.loadFlagsParameters()
            this.resetValidation()
            this.$refs.formModal.show()
        },
        loadFlagsParameters() {
            this.flagsParameters = this.formatParameters({
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                chain_id: this.posModal.chain_id ? this.posModal.chain_id : 0,
            })

            this.$nextTick(() => {
                this.$refs.flagMultiselect.loadOptions(this.flagsParameters)
            })
        },
        changeChainId(e) {
            const oldChainId = this.posModal.chain_id
            this.posModal.chain_id = e ? e.id : null
            this.clearResponseError('chain', 'posModal')
            this.loadFlagsParameters()

            if (this.posModal.chain_id !== oldChainId) {
                this.posModal.flag = null
            }
        },
        replaceModalData(data) {
            Object.assign(this.gModal, data)
        },
        cleanModal() {
            this.posModal = Object.assign(this.posModal, {
                id: null,
                name: '',
                externalCode: '',
                chainExternalCode: '',
                registeredNumber: '',
                chain: null,
                channel: null,
                flag: null,
                regional_id: null,
                regional: null,
                category: null,
                address: null,
                city: {
                    id: null,
                    name: '',
                },
                state: {
                    id: null,
                    letter: '',
                    name: '',
                    slug: '',
                },
                latitude: null,
                longName: null,
                longitude: null,
                lat: null,
                long: null,
                complement: null,
                district: null,
                shortName: null,
                postalCode: null,
                zip_code: null,
                number: null,
                placeId: null,
                humanReadableAddress: null,
                number_checkout: 0,
                business_units: [],
                commecial_responsible_name: null,
                commecial_responsible_email: null,
                merchandising_responsib_name: null,
                merchandising_responsib_email: null,
            })

            this.$v.posModal.$reset()

            if (this.$refs.gautocomplete) {
                this.$refs.gautocomplete.cleanModal()
            }
        },
        handleEdit() {
            this.editLoading = true

            this.isEdit = true
            this.tabIndex = 0
            this.editLoading = false
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.posModal.$touch()
            this.$refs.gautocomplete.$v.gModal.$touch()
            if (!this.$v.posModal.$invalid && !this.$refs.gautocomplete.$v.gModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            /* eslint-disable camelcase */
            const posInfo = {
                name: this.posModal.name,
                cnpj: this.posModal.registeredNumber ? this.posModal.registeredNumber.replace(/[^\d]+/g, '') : null,
                number_checkout: this.posModal.number_checkout,
                category_id: this.posModal.category ? this.posModal.category.id : null,
                chain_id: this.posModal.chain ? this.posModal.chain.id : null,
                regional_id: this.posModal.regional ? this.posModal.regional.id : null,
                flag_id: this.posModal.flag ? this.posModal.flag.id : null,
                channel_id: this.posModal.channel ? this.posModal.channel.id : null,
                business_units: this.posModal.business_units.map(businessUnit => businessUnit.id),
                external_code: this.posModal.externalCode,
                chain_external_code: this.posModal.chainExternalCode,
                commecial_responsible_name: this.posModal.commecial_responsible_name,
                commecial_responsible_email: this.posModal.commecial_responsible_email,
                merchandising_responsib_name: this.posModal.merchandising_responsib_name,
                merchandising_responsib_email: this.posModal.merchandising_responsib_email,
                lat: this.$refs.gautocomplete.gModal.latitude,
                route: this.$refs.gautocomplete.gModal.route,
                number: this.$refs.gautocomplete.gModal.number,
                long: this.$refs.gautocomplete.gModal.longitude,
                address: this.$refs.gautocomplete.gModal.address,
                long_name: this.$refs.gautocomplete.gModal.longName,
                short_name: this.$refs.gautocomplete.gModal.shortName,
                district: this.$refs.gautocomplete.gModal.district,
                place_id: this.$refs.gautocomplete.gModal.placeId,
                zip_code: this.$refs.gautocomplete.gModal.postalCode,
                complement: this.$refs.gautocomplete.gModal.complement,
                city_id: this.$refs.gautocomplete.gModal.city_id ? this.$refs.gautocomplete.gModal.city_id : null,
                city: this.$refs.gautocomplete.gModal.city ? this.$refs.gautocomplete.gModal.city.label : null,
                state: this.$refs.gautocomplete.gModal.state ? this.$refs.gautocomplete.gModal.state.label : null,
            }
            const operation = this.isPosCreated
                ? PosService.update(this.posModal.id, posInfo)
                : PosService.create(posInfo)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nPos, this.modalOperation, 0)
            }

            if (statusCode === '417') {
                if (response.data.data.message === 'conflicting_point_of_sale') {
                    const message = `${this.getI18n(
                        'ERROR_CODES',
                        'existing_point_of_sale'
                    )} ${this.getI18n('POS', 'TITLES.pos')}: ${
                        response.data.data.point_of_sale.name
                    }`
                    this.negativeFeedback(
                        this.getI18n('RESPONSES', 'something_wrong'),
                        message
                    )
                }
            }

            if (statusCode === '422') {
                const errors = response.data.errors
                const camelCaseErrors = {}
                if (errors) {
                    Object.keys(errors).forEach((error) => {
                        camelCaseErrors[this.transformToCamelCase(error)] =
                            errors[error]
                    })
                }
                this.modalResponseErrors = camelCaseErrors
                this.warningFeedbackApi(this.i18nPos, response.data.errors)
            }

            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deletePos)
        },
        async deletePos(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.posModal.$touch()
            this.deleteLoading = true
            const response = await PosService.delete(this.posModal.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nPos, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        handleRemoveBusinessUnit(id) {
            const index = this.posModal.business_units.findIndex(find => find.id === id);

            if (index > -1) {
                this.posModal.business_units.splice(index, 1);
            }
        },
        showCategoryModal() {
            this.$nextTick(() => this.$refs.posCategoryModal.showModal())
        },
        showChainModal() {
            this.$nextTick(() => this.$refs.posChainModal.showModal())
        },
        showChannelModal() {
            this.$nextTick(() => this.$refs.posChannelModal.showModal())
        },
        showRegionalModal() {
            this.$nextTick(() => this.$refs.posRegionalModal.showModal())
        },
        showFlagModal() {
            this.$nextTick(() => this.$refs.posFlagModal.showModal())
        },
        reloadCategoryOptions() {
            this.$nextTick(() => this.$refs.categoryMultiselect.refresh())
        },
        reloadChainOptions() {
            this.$nextTick(() => this.$refs.chainMultiselect.refresh())
        },
        reloadChannelOptions() {
            this.$nextTick(() => this.$refs.channelMultiselect.refresh())
        },
        reloadRegionalOptions() {
            this.$nextTick(() => this.$refs.regionalMultiselect.refresh())
        },
        reloadFlagOptions() {
            this.$nextTick(() => this.$refs.flagMultiselect.refresh())
        },
    },
}
</script>

<style>
</style>
